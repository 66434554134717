import {
	Box,
	Heading,
	Text,
	Flex,
	IconButton,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	useDisclosure,
	ModalCloseButton,
	Button,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import ChatList from '../ChatList/index'
import { MdAddCircle } from 'react-icons/md'
import GroupUser from './GroupUser/indes'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../../../containers/repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'

const GroupList = ({ groupUsers, removeParticipant, inviteUser, user }) => {
	const [isNewChat, setisNewChat] = useState(true)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const {
		state: { locale },
	} = useLanguageContext()
	return (
		<Box padding='4' height={'100%'}>
			<Heading as='h3' size='md' marginBottom='2'>
				<Flex justifyContent='space-between'>
					<Text fontSize='16px' alignSelf='flex-end' fontWeight='bold'>
						Participants :- {groupUsers.length}
					</Text>
					{user.operating_asset_first_name ===
					groupUsers?.[0]?.activity_creator_operating_asset_first_name ? (
						<Box textAlign='center'>
							<Button
								leftIcon={<MdAddCircle size='27' />}
								size='sm'
								bg={localStorage.getItem('color')}
								colorScheme={localStorage.getItem('color')}
								variant='solid'
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_NAVBAR,
									//   action: 'Chat View',
									//   label: 'Add Participants',
									// })
									// dataLayerTagManager('button_click', {
									//   viewName: 'Chat',
									//   buttonName: 'Add participants',
									// })
									onOpen()
								}}
							>
								Add Participants
							</Button>
							{/* <IconButton
                size="sm"
                aria-label="Search database"
                onClick={() => {
                  sectionDetailedTrack({
                    category: GA_CATEGORY_NAVBAR,
                    action: 'Chat View',
                    label: 'Add Participants',
                  })
                  dataLayerTagManager('button_click', {
                    viewName: 'Chat',
                    buttonName: 'Add participants',
                  })
                  onOpen()
                }}
                icon={<MdAddCircle size="27" />}
              />
              <Text fontSize="16px" alignSelf="flex-end" fontWeight="bold">
                Add Participants
              </Text> */}
						</Box>
					) : null}
				</Flex>
			</Heading>
			<Box height='82%' overflowY='auto'>
				<GroupUser
					groupUsers={groupUsers}
					removeParticipant={removeParticipant}
					user={user}
				/>
			</Box>
			<Modal size={'3xl'} isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						{' '}
						<Text fontSize='16px' alignSelf='flex-end' fontWeight='bold'>
							Add Participants
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text fontSize='16px' alignSelf='flex-end' fontWeight='bold'>
							Participants :- {groupUsers.length}{' '}
						</Text>
						<Flex>
							<Box width='45%' height='460px' overflowY='auto'>
								<GroupUser
									groupUsers={groupUsers}
									removeParticipant={removeParticipant}
									user={user}
								/>
							</Box>
							<Box marginTop='-10' width='55%'>
								<ChatList
									isFromGroup={true}
									setchatView={true}
									onProfileSelection={true}
									setselectedProfile={true}
									chatInitializer={true}
									user={{}}
									isNewChat={isNewChat}
									setisNewChat={setisNewChat}
									inviteUser={inviteUser}
									groupUsers={groupUsers}
								/>
							</Box>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Box>
	)
}

export default GroupList
