import React from 'react';
import { Box, Tag, Avatar, Text, IconButton } from '@chakra-ui/react';
import { getFromNow, getCurrentDateUTC } from '../../../../utils/date.utils';
import { downloadAwsFile } from '../../../../utils/aws.util'
import { BsDownload } from "react-icons/bs";
const SingleMessage = ({ messages, user, index }) => {
  const convertUnicode = input => {
    return input.replace(/\\u(\w{4,4})/g, function (a, b) {
      var charcode = parseInt(b, 16);
      return String.fromCharCode(charcode);
    });
  };

  const onImgDownload = async (attachments) => {
    await Promise.all(attachments.map(async (val) => {
      await downloadAwsFile(val)
    }))
  }
  const toReturnName = url => {
    return url.split('/').pop()
  }
  let isHasAttachment = (typeof messages?.data_entity_inline === 'string'
    ? JSON.parse(messages?.data_entity_inline || '')
    : messages?.data_entity_inline
  ).attachments;
  let attachmentData = isHasAttachment?.length ? toReturnName(isHasAttachment[0]) : ''

  return (
    <Box key={index} marginBottom="8">
      {Object.keys(
        typeof messages?.data_entity_inline === 'string'
          ? JSON.parse(messages?.data_entity_inline || '')
          : messages?.data_entity_inline
      ).length > 0 ? (
        Number(messages?.asset_id) === user?.asset_id ? (
          <Box
            style={{ position: 'relative', marginBottom: '20px' }}
            className={`timeline-message-all-type `}
          >
            <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <Avatar
                size="sm"
                marginLeft="2"
                name={messages?.operating_asset_first_name}
              />
              {!!isHasAttachment.length && <IconButton
                variant='ghost'
                colorScheme='gray'
                aria-label='See menu'
                cursor='pointer'
                size='md'
                onClick={() => onImgDownload(isHasAttachment)}
                icon={<BsDownload size="27" />}
              />
              }
              <Tag
                paddingY="1"
                fontSize="sm"
                dangerouslySetInnerHTML={{
                  __html: !attachmentData ? convertUnicode(
                    unescape(
                      (typeof messages?.data_entity_inline === 'string'
                        ? JSON.parse(messages?.data_entity_inline || '')
                        : messages?.data_entity_inline
                      ).content
                    )
                  ) : attachmentData,
                }}
              />

            </Box>
            <Text style={{ position: 'absolute', right: 0 }}>
              {getFromNow(messages?.datetime_log || getCurrentDateUTC())}
            </Text>
          </Box>
        ) : (
          <Box
            style={{ position: 'relative', marginBottom: '20px' }}
            className={`timeline-message-all-type`}
          >
            <Box style={{ display: 'flex' }}>
              <Avatar
                size="sm"
                bgcolor={localStorage.getItem('color')}
                color="white"
                marginRight="2"
                name={messages?.operating_asset_first_name}
              />
              {!!isHasAttachment.length && <IconButton
                variant='ghost'
                colorScheme='gray'
                aria-label='See menu'
                cursor='pointer'
                size='md'
                onClick={() => onImgDownload(isHasAttachment)}
                icon={<BsDownload size="27" />}
              />
              }
              <Tag
                paddingY="1"
                dangerouslySetInnerHTML={{
                  __html: !attachmentData ? convertUnicode(
                    unescape(
                      (typeof messages?.data_entity_inline === 'string'
                        ? JSON.parse(messages?.data_entity_inline || '')
                        : messages?.data_entity_inline
                      ).content
                    )
                  ) : attachmentData,
                }}
              />
            </Box>
            <Text style={{ position: 'absolute', left: 0 }}>
              {getFromNow(messages?.datetime_log || getCurrentDateUTC())}
            </Text>
          </Box>
        )
      ) : null}
    </Box>
  );
};

export default SingleMessage;
