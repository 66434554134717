/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	Box,
	IconButton,
	Input,
	Divider,
	Flex,
	Text,
	InputLeftElement,
	InputGroup,
	Skeleton,
	Button,
	Heading,
	VStack,
} from '@chakra-ui/react'
import { MdSearch, MdArrowBack, MdGroupAdd, MdSend } from 'react-icons/md'
import { CHAT_PANEL_VIEW } from '../indes'
import { LoadChatData } from '../../../../hooks/asset.hooks'
import { useLoadCoWorkersList } from '../../../../hooks/shared.hooks'
import { useAuthContext } from '../../../../context/auth.context'
import { useLanguageContext } from '../../../../context/locale.context'
import { getCurrentDateUTC } from '../../../../utils/common.util'
import SingleChatCard from '../SingleChatCard'
import { useSharedContext } from '../../../../context/shared.context'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../../../containers/repository/repo.utils'

const ChatList = ({
	setchatView,
	onProfileSelection,
	user,
	setisNewChat,
	isNewChat,
	chatInitializer,
	//group chat states
	isFromGroup,
	inviteUser,
	groupUsers,
}) => {
	const { mutate, isLoading } = LoadChatData()
	const { mutate: loadCoWorkers, isLoading: isworkersLoading } =
		useLoadCoWorkersList()

	const [chatData, setchatData] = useState([])
	const [serchString, setserchString] = useState('')
	const [isSerchChange, setisSerchChange] = useState(false)
	const [isGroupChat, setisGroupChat] = useState(false)
	const [dummy, setdummy] = useState(true)
	const [groupList, setGroupList] = useState([])
	const [groupName, setGroupName] = useState('')
	const [isGroupSubmites, setisGroupSubmites] = useState(false)
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { state, dispatch } = useSharedContext()
	const { isRefreshChat, refreshChatList } = state || {}

	useEffect(() => {
		getChatList()
	}, [isRefreshChat, refreshChatList])

	const getChatList = () => {
		mutate(
			{
				asset_message_counter: 0,
				message_unique_id: 1636353695988,
				track_latitude: 0,
				track_longitude: 0,
				track_altitude: 0,
				track_gps_datetime: getCurrentDateUTC(new Date()),
				track_gps_accuracy: 0,
				track_gps_status: 0,
				page_limit: 50,
				page_start: 0,
			},
			{
				onSuccess: async data => {
					if (isFromGroup) {
						let val = data.filter(
							val =>
								groupUsers.findIndex(
									ele =>
										ele.operating_asset_first_name ===
											val.operating_asset_first_name &&
										ele.operating_asset_phone_number ===
											JSON.parse(val.activity_inline_data).contact_phone_number
								) === -1
						)
						setchatData(val)
					}
					setchatData(data)
				},
				onError: async err => {
					console.log(err)
				},
			}
		)
	}

	const returnUserName = profile => {
		let userName
		if (isSerchChange) {
			userName = profile.operating_asset_first_name
		} else {
			let activityTitle = profile.activity_title.split('-')
			if (user.asset_id === profile.activity_creator_asset_id) {
				//props.loguser
				userName = activityTitle[1]
			} else {
				userName = activityTitle[0]
			}
			if (profile.activity_type_category_id === 27) {
				userName = profile.activity_title
			}
			if (userName === undefined) {
				userName = profile.activity_title
			}
		}
		return userName
	}

	const onSerch = () => {
		loadCoWorkers(
			{
				is_search: 1,
				is_sort: 0,
				is_status: 0,
				limit_value: 50,
				start_from: 0,
				activity_type_category_id: 5,
				search_string: serchString,
			},
			{
				onSuccess: async data => {
					let val
					if (isFromGroup) {
						val = data.filter(
							val =>
								groupUsers.findIndex(
									ele =>
										ele.operating_asset_first_name ===
											val.operating_asset_first_name &&
										ele.operating_asset_phone_number ===
											JSON.parse(val.activity_inline_data).contact_phone_number
								) === -1
						)
					} else {
						val = data.filter(
							val =>
								groupList.findIndex(
									ele => ele.activity_id === val.activity_id
								) === -1
						)
					}
					setchatData(val)
				},
				onError: async err => {
					console.log(err)
				},
			}
		)
	}

	useEffect(() => {
		if (serchString) {
			setisSerchChange(true)
			setisNewChat(true)
			onSerch()
		} else {
			// setisNewChat(false)
		}
	}, [serchString])

	const onGoBack = () => {
		getChatList()
		setserchString('')
		setisNewChat(false)
		setisSerchChange(false)
		setisGroupChat(false)
		setGroupList([])
		setGroupName('')
		setisGroupSubmites(false)
	}

	const onChatListClick = async user => {
		if (isGroupChat) return
		setisNewChat(false)
		onProfileSelection(user)
		chatInitializer(user)
		setchatView(CHAT_PANEL_VIEW.CHAT_VIEW)
	}

	const onAddGroup = () => {
		typeof setchatView === 'function' && setchatView(CHAT_PANEL_VIEW.LIST_VIEW)
		setchatData([])
		setisGroupChat(true)
		setisSerchChange(true)
		onSerch()
	}
	useEffect(() => {
		if (isFromGroup) {
			onAddGroup()
		}
	}, [groupUsers])

	const addPersonToGroup = item => {
		if (isFromGroup) {
			inviteUser(item)
		} else {
			let data = groupList
			data.push(item)
			setGroupList(data)
			let val = chatData.filter(
				val =>
					groupList.findIndex(ele => ele.activity_id === val.activity_id) === -1
			)
			setchatData(val)
			setdummy(!dummy)
		}
	}

	const removePersonToGroup = indx => {
		let data = groupList
		data.splice(indx, 1)
		setGroupList(data)
		setdummy(!dummy)
		onSerch()
	}

	const onGroupCreat = () => {
		setisGroupSubmites(true)
		if (!groupName || !groupList.length) return
		setisNewChat(true)
		setdummy(!dummy)
		let group = {
			groupName: groupName,
			isGroupChat: true,
			userList: groupList,
		}
		chatInitializer(group)
		setchatView(CHAT_PANEL_VIEW.CHAT_VIEW)
		setisGroupChat(false)
		setGroupList([])
		setGroupName('')
		setisGroupSubmites(false)
		setisSerchChange(false)
		setserchString('')
	}

	return (
		<>
			<Heading m='10px'>
				{isSerchChange ? (
					!isFromGroup && (
						<>
							<Flex alignItems='center'>
								<IconButton
									aria-label='Call Segun'
									size='md'
									icon={<MdArrowBack />}
									onClick={() => {
										// sectionDetailedTrack({
										//   category: GA_CATEGORY_NAVBAR,
										//   action: 'Chat List',
										//   label: 'Go Back',
										// })
										onGoBack()
									}}
								/>
								<Text
									fontSize='24'
									alignSelf='flex-end'
									fontWeight='bold'
									marginLeft='4'
								>
									{user?.operating_asset_first_name}-
								</Text>
							</Flex>
						</>
					)
				) : (
					<Flex justifyContent='space-between' alignItems='center'>
						<Text
							textTransform='uppercase'
							fontSize='16px'
							alignSelf='flex-end'
							fontWeight='bold'
						>
							{locale['Grene Chat']}
						</Text>
						<Button
							leftIcon={<MdGroupAdd />}
							size='sm'
							bgColor='#4daf89'
							bg={localStorage.getItem('color')}
							variant='solid'
							onClick={() => {
								// sectionDetailedTrack({
								//   category: GA_CATEGORY_NAVBAR,
								//   action: 'Chat List',
								//   label: 'Create Group',
								// })
								// dataLayerTagManager('button_click', {
								//   viewName: 'Chat',
								//   buttonName: 'Create Group',
								// })
								onAddGroup()
							}}
						>
							{locale['Create Group']}
						</Button>
					</Flex>
				)}
				<InputGroup marginTop='2'>
					<InputLeftElement
						pointerEvents='none'
						color={localStorage.getItem('color')}
						children={<MdSearch fontSize='25' />}
					/>
					<Input
						type='tel'
						width='100%'
						placeholder={locale['Search user...']}
						value={serchString}
						onChange={e => {
							// dataLayerTagManager('button_click', {
							//   viewName: 'Chat',
							//   buttonName: 'Search User',
							// })
							setserchString(e.target.value)
						}}
					/>
				</InputGroup>
			</Heading>
			<Divider />
			<Box display='flex' flexDirection='row'>
				<Box
					height='60vh'
					overflowY='scroll'
					m='10px'
					width='100%'
					sx={{
						'::-webkit-scrollbar': {
							width: '3px',
						},
						'::-webkit-scrollbar-thumb': {
							outline: `1px solid ${localStorage.getItem('color')}`,
							borderRadius: '50px',
							border: 'solid 3px transparent',
							backgroundClip: 'content-box',
							WebkitBackgroundClip: 'content-box',
						},
					}}
				>
					{chatData?.length ? (
						(chatData || []).map((item, i) => (
							<SingleChatCard
								key={i}
								isGroupChat={isGroupChat}
								index={i}
								returnUserName={returnUserName}
								item={item}
								onChatListClick={onChatListClick}
								addPersonToGroup={addPersonToGroup}
								isSelected={false}
								removePersonToGroup={removePersonToGroup}
							/>
						))
					) : isLoading || isworkersLoading ? (
						[1, 2, 3, 4, 5, 6, 7, 8].map(i => {
							return (
								<Skeleton
									boxShadow='md'
									borderRadius='md'
									key={i}
									mb={2}
									w='full'
									h='53px'
								/>
							)
						})
					) : (
						<Text
							color='black'
							fontSize='lg'
							textAlign='center
          '
							marginTop='3'
						>
							{isSerchChange
								? locale['No data to display']
								: locale['No Chat history to display']}
						</Text>
					)}
				</Box>
				<Box width={isGroupChat && groupList.length ? '40%' : null}>
					{isGroupChat && groupList.length ? (
						<Box height='45%' padding='4' position='relative'>
							<Text
								fontSize='24px'
								textAlign='center'
								fontWeight='bold'
								marginBottom='3'
								position='sticky'
							>
								Selected Users :- {groupList.length}{' '}
							</Text>

							<Divider />
							<Box
								overflowY='scroll'
								height='55vh'
								sx={{
									'::-webkit-scrollbar': {
										width: '3px',
									},
									'::-webkit-scrollbar-thumb': {
										outline: `1px solid ${localStorage.getItem('color')}`,
										borderRadius: '50px',
										border: 'solid 3px transparent',
										backgroundClip: 'content-box',
										WebkitBackgroundClip: 'content-box',
									},
								}}
							>
								{(groupList || []).map((item, i) =>
									item.activity_type_category_id !== 27 ? (
										<SingleChatCard
											isGroupChat={isGroupChat}
											index={i}
											key={i}
											returnUserName={returnUserName}
											item={item}
											onChatListClick={onChatListClick}
											addPersonToGroup={addPersonToGroup}
											isSelected={true}
											removePersonToGroup={removePersonToGroup}
										/>
									) : null
								)}
							</Box>
							<Divider />
							{groupList.length >= 2 ? (
								<Box>
									<Flex width='93%' m='3'>
										<Input
											placeholder={locale['Enter Group Name']}
											value={groupName}
											onChange={e => setGroupName(e.target.value)}
										/>
										<IconButton
											aria-label='Search database'
											marginLeft='2'
											icon={<MdSend />}
											onClick={() => {
												// sectionDetailedTrack({
												//   category: GA_CATEGORY_NAVBAR,
												//   action: 'Chat List',
												//   label: locale['Send'],
												// })
												onGroupCreat()
											}}
										/>
									</Flex>
									{isGroupSubmites && !groupName ? (
										<Text
											position='absolute'
											marginTop='10'
											bottom='0'
											color='red.400'
										>
											Please enter a Group Name
										</Text>
									) : null}
								</Box>
							) : null}
						</Box>
					) : null}
				</Box>
			</Box>
		</>
	)
}

export default ChatList
