/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useRef } from 'react'
import {
	Input,
	Divider,
	Flex,
	Text,
	IconButton,
	Button,
	Box,
	Heading,
	Tooltip,
	Skeleton,
	Icon,
} from '@chakra-ui/react'
import { useAuthContext } from '../../../../context/auth.context'
import { useSharedContext } from '../../../../context/shared.context'
import Card from '../../../card'
import {
	MdSend,
	MdArrowBack,
	MdInfo,
	MdModeEdit,
	MdCheckCircle,
} from 'react-icons/md'
import { CHAT_PANEL_VIEW } from '../indes'
import SingleMessage from '../SingleMessage'
import {
	useLoadGroupParticipants,
	useAlterFileCover,
	useAddTimelineUpdate,
	useRemoveParticipant,
	useInviteParticipant,
} from '../../../../hooks/activity.hooks'
import {
	formatDueDateForTimelineCurrDate,
	formatNoteUpdateDate,
} from '../../../../utils/date.utils'
import GroupList from '../GroupList'
import { sharedConst } from '../../../../utils/action.constant'
import { LoadChatData } from '../../../../hooks/asset.hooks'
import {
	getCurrentDateUTC,
	getMessageUniqueId,
} from '../../../../utils/common.util'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../../../containers/repository/repo.utils'
import { useLanguageContext } from '../../../../context/locale.context'
import { ImAttachment } from 'react-icons/im'
import { pushFileToS3 } from '../../../../utils/aws.util'
import { BsX } from 'react-icons/bs'
import { useDeepStreamContext } from '../../../../context/deepstream.context'

export const GROUP_PANEL_VIEW = {
	GROUP_CHAT_VIEW: 'GROUP_CHAT_VIEW',
	GROUP_INFO_VIEW: 'GROUP_INFO_VIEW',
}

const ChatView = ({
	setchatView,
	user,
	chatData,
	onMessageSubmit,
	setisNewChat,
	isNewChat,
	onProfileSelection,
	setchatData,
	setselectedProfile,
	isChatLoading,
}) => {
	const [chanelName, setchanelName] = useState('')
	const [message, setmessage] = useState('')
	const [onlineStatus, setonlineStatus] = useState('')
	const messagesEndRef = useRef(null)
	const [groupUsers, setgroupUsers] = useState([])
	const { mutate: loadParticipant } = useLoadGroupParticipants()
	const [groupView, setgroupView] = useState('')
	const [isGroupEdit, setisGroupEdit] = useState(false)
	const [groupName, setGroupName] = useState('')
	const [attachmentURl, setattachmentURl] = useState('')
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const {
		state: { ds },
	} = useDeepStreamContext()
	const { mutate: inviteMutate } = useInviteParticipant()
	const { mutate: mutateFileEditSave, isLoading: isActivityUpdating } =
		useAlterFileCover()
	const { mutate: mutateaddTimeLineUpdate } = useAddTimelineUpdate()
	const { mutate: loadChatData } = LoadChatData()
	const { mutate: removeMutate } = useRemoveParticipant()
	const { state, dispatch } = useSharedContext()
	const { isRefreshChat, chatSelectedProfile } = state || {}
	const inputRef = useRef()
	const goBack = () => {
		if (groupView === GROUP_PANEL_VIEW.GROUP_INFO_VIEW) {
			setgroupView('')
			setisGroupEdit(false)
		} else {
			setchatView(CHAT_PANEL_VIEW.LIST_VIEW)
			setchatData([])
		}
	}

	useEffect(() => {
		onProfileSelection(chatSelectedProfile)
	}, [isRefreshChat])

	useEffect(() => {
		if (groupView === GROUP_PANEL_VIEW.GROUP_INFO_VIEW) {
			setgroupView('')
			setisGroupEdit(false)
		}
		toGetChatTitle()
		if (
			!!ds &&
			Object.keys(chatSelectedProfile).length > 0 &&
			!chatSelectedProfile?.isGroupChat
		) {
			if (!!ds && chatSelectedProfile?.activity_type_category_id !== 27)
				toShowStatus()
		}
	}, [chatSelectedProfile, ds])

	useEffect(() => {
		messagesEndRef.current?.scrollIntoView()
	}, [chatData, groupView])

	const toShowStatus = () => {
		let ChannelName = ''
		let hereNowUserId = 0
		if (chatSelectedProfile) {
			if (chatSelectedProfile.hasOwnProperty('activity_id')) {
				hereNowUserId = chatSelectedProfile.activity_lead_asset_id
			} else if (chatSelectedProfile.isGroupChat) {
				hereNowUserId = chatSelectedProfile.groupName
			} else {
				hereNowUserId = chatSelectedProfile.value?.contact_asset_id
			}

			ChannelName =
				user.asset_id > chatSelectedProfile.asset_id
					? chatSelectedProfile.asset_id + '-' + user.asset_id
					: user.asset_id + '-' + chatSelectedProfile.asset_id
			if (chatSelectedProfile.hasOwnProperty('activity_id')) {
				ChannelName = chatSelectedProfile.activity_title
			} else {
				ChannelName =
					user.asset_id > chatSelectedProfile.asset_id
						? chatSelectedProfile.asset_id + '-' + user.asset_id
						: user.asset_id + '-' + chatSelectedProfile.asset_id
			}
		}
		console.log({ ChannelName })
		if (!!ChannelName && ds) {
			ds.event.subscribe(ChannelName, data => {})
		}

		const UserID = hereNowUserId
	}

	const toGetChatTitle = () => {
		let userName = user.operating_asset_first_name
		if (chatSelectedProfile && !isNewChat) {
			let activityTitle = chatSelectedProfile.activity_title.split('-')
			if (user.asset_id === chatSelectedProfile.activity_creator_asset_id) {
				userName = activityTitle[1]
			} else {
				userName = activityTitle[0]
			}
			if (chatSelectedProfile.activity_type_category_id === 27) {
				userName = chatSelectedProfile.activity_title
			}
		}
		let title
		title =
			isNewChat && chatSelectedProfile && chatSelectedProfile.isGroupChat
				? chatSelectedProfile.groupName
				: isNewChat
				? `${userName} - ${
						chatSelectedProfile &&
						chatSelectedProfile.operating_asset_first_name
							? chatSelectedProfile.operating_asset_first_name
							: ''
				  }`
				: chatSelectedProfile && chatSelectedProfile.activity_title
		if (
			chatSelectedProfile.activity_type_category_id === 16 ||
			chatSelectedProfile.activity_type_category_id === 27
		) {
			title = chatSelectedProfile.activity_title
		}
		setchanelName(title)
	}

	const groupInfo = () => {
		setgroupView(GROUP_PANEL_VIEW.GROUP_INFO_VIEW)
		setGroupName(chanelName)
		loadParticipant(
			{
				activity_id: chatSelectedProfile.activity_id,
				datetime_differential: '1970-01-01 00:00:00',
				limit_value: 50,
				page_limit: 50,
				page_start: 0,
				start_from: 0,
			},
			{
				onSuccess: async data => {
					setgroupUsers(data)
				},
			}
		)
	}

	// downloadAwsFile('https://worlddesk-staging-j21qqcnj.s3.ap-south-1.amazonaws.com/868/1102/5918/39749/2022/11/103/397491669632745361/Screenshot-from-2022-08-25-16-26-28.png')
	const onKeyDown = event => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter') {
			event.preventDefault()
			event.stopPropagation()
			onMessageSubmit({
				message,
				user: chatSelectedProfile,
				url: attachmentURl,
			})
			setmessage('')
			setattachmentURl('')
		}
	}

	const buildTitleUpdateParams = updatedTitle => {
		const {
			activity_title,
			activity_datetime_end_deferred,
			activity_id,
			activity_type_id,
		} = chatSelectedProfile
		const coverPayload = {
			title: {
				old: activity_title,
				new: updatedTitle,
			},
			description: { old: '', new: '' },
			duedate: {
				old: activity_datetime_end_deferred,
				new: activity_datetime_end_deferred,
			},
		}
		return {
			activity_id: activity_id,
			activity_cover_data: JSON.stringify(coverPayload),
			activity_type_id: activity_type_id,
		}
	}

	const buildTimelineEntryParams = updatedTitle => {
		const {
			activity_title,
			activity_id,
			activity_type_category_id,
			parent_activity_id,
			activity_type_id,
		} = chatSelectedProfile
		const { operating_asset_first_name } = authData
		let activityTimelineJson = {
			content: `Activity title changed from "${
				activity_title ? activity_title : chanelName
			}" to "${updatedTitle}" by ${operating_asset_first_name} @
                ${formatDueDateForTimelineCurrDate()}`,
			subject: 'Note - ' + formatNoteUpdateDate(),
			mail_body: `Activity title changed from "${
				activity_title ? activity_title : chanelName
			}" to "${updatedTitle}" by ${operating_asset_first_name} @
                ${formatDueDateForTimelineCurrDate()}`,
			attachments: [],
			activity_reference: [
				{
					activity_title: '',
					activity_id: '',
				},
			],
			asset_reference: [],
			form_approval_field_reference: [],
		}
		return {
			activity_id: activity_id,
			activity_type_category_id: activity_type_category_id,
			activity_type_id: activity_type_id,
			activity_parent_id: parent_activity_id,
			activity_timeline_collection: JSON.stringify(activityTimelineJson),
			activity_stream_type_id: 711,
			timeline_stream_type_id: 711,
			// data_entity_inline: JSON.stringify(activityTimelineJson),
			operating_asset_first_name: operating_asset_first_name,
		}
	}

	//change group name:---
	const onRenameActivity = activityName => {
		if (!activityName) return
		let activity = chatSelectedProfile
		const { activity_title } = chatSelectedProfile
		if (activity_title !== activityName) {
			let titleParams = buildTitleUpdateParams(activityName)
			let timelineParams = buildTimelineEntryParams(activityName)
			mutateFileEditSave(
				{ activity, ...titleParams },
				{
					onSuccess: async () => {
						loadChatData(
							{
								asset_message_counter: 0,
								message_unique_id: 1636353695988,
								track_latitude: 0,
								track_longitude: 0,
								track_altitude: 0,
								track_gps_datetime: getCurrentDateUTC(new Date()),
								track_gps_accuracy: 0,
								track_gps_status: 0,
								page_limit: 50,
								page_start: 0,
							},
							{
								onSuccess: async data => {
									setisGroupEdit(false)
									let selectdValue = data.find(
										val => val.activity_id === chatSelectedProfile.activity_id
									)
									onProfileSelection(selectdValue)
								},
								onError: async err => {
									console.log(err)
								},
							}
						)
						mutateaddTimeLineUpdate(
							{ ...timelineParams },

							{
								onSuccess: async () => {
									dispatch({
										type: sharedConst.REFRESH_CHAT_COUNT,
										isRefreshChat: false,
									})
								},
							}
						)
					},
				}
			)
		}
	}

	const removeParticipant = useCallback(participant => {
		const { activity_id } = chatSelectedProfile
		removeMutate(
			{
				activity_id,
				activity_participant_collection: JSON.stringify([
					{
						...participant,
						access_role_id: 0,
						message_unique_id: getMessageUniqueId(),
					},
				]),
			},
			{
				onSuccess: async data => {
					groupInfo()
				},
				onError: async error => {
					console.log('remove error', error)
				},
			}
		)
	})

	const inviteUser = useCallback(
		({
			// contact_asset_type_id,
			// contact_asset_id,
			// contact_first_name,
			// contact_operating_asset_name,
			asset_type_id,
			asset_id,
			asset_first_name,
			operating_asset_first_name,
			workforce_name,
		}) => {
			const { activity_id, activity_type_id } = chatSelectedProfile
			inviteMutate(
				{
					activity_id,
					activity_type_category_id: 27,
					activity_type_id,
					contact: {
						asset_type_id: asset_type_id,
						asset_id: asset_id,
						asset_first_name: asset_first_name,
						operating_asset_first_name: operating_asset_first_name,
						workforce_name,
					},
				},
				{
					onSuccess: async data => {
						groupInfo()
					},
				}
			)
		}
	)

	const onHandleFileChange = async event => {
		const file = event.target['files'][0]
		const blob = file.slice(0, file.size, file.type)
		const newFile = new File([blob], file.name.replace(/ /gi, '-'), {
			type: blob.type,
		})
		let result = await pushFileToS3(newFile)
		const [{ url }] = await Promise.all([result])
		setattachmentURl(url)
		setmessage(toReturnName(url))
	}

	const clearFiles = () => {
		setattachmentURl('')
		setmessage('')
	}

	const toReturnName = url => {
		return url.split('/').pop()
	}

	return (
		<>
			{isChatLoading ? (
				[1, 2, 3, 4, 5, 6, 7, 8].map(i => {
					return (
						<Skeleton
							boxShadow='md'
							borderRadius='md'
							key={i}
							mb={2}
							w='full'
							h='53px'
						/>
					)
				})
			) : (
				<>
					<Heading>
						<Flex alignItems='center' justifyContent='space-between'>
							<Flex alignItems='center' height='50px'>
								<IconButton
									aria-label='Call Segun'
									size='sm'
									icon={<MdArrowBack />}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_NAVBAR,
										// 	action: 'Chat View',
										// 	label: 'Go Back',
										// })
										goBack()
									}}
									background='#fff'
								/>
								{groupView === GROUP_PANEL_VIEW.GROUP_INFO_VIEW ? (
									<Flex alignItems='center'>
										{!isGroupEdit ? (
											<React.Fragment>
												<Text
													fontSize='24px'
													alignSelf='flex-end'
													fontWeight='bold'
													marginLeft='4'
												>
													{chanelName}
												</Text>
												{/* <Text marginLeft="4" color="#2D3748">
                    </Text> */}
												{user.operating_asset_first_name ===
													groupUsers?.[0]
														?.activity_creator_operating_asset_first_name && (
													<Text
														marginLeft='2'
														cursor='pointer'
														padding='2'
														onClick={() => {
															// sectionDetailedTrack({
															// 	category: GA_CATEGORY_NAVBAR,
															// 	action: 'Chat View',
															// 	label: 'Group Edit',
															// })
															// dataLayerTagManager('button_click', {
															// 	viewName: 'Chat',
															// 	buttonName: 'Group Name Edit',
															// })
															setisGroupEdit(true)
														}}
													>
														<MdModeEdit color='teal' size={16} />
													</Text>
												)}
											</React.Fragment>
										) : (
											<React.Fragment>
												<Input
													marginLeft='2'
													size='sm'
													placeholder='Enter Group Name'
													value={groupName}
													onChange={e => setGroupName(e.target.value)}
												/>
												<Text
													marginLeft='2'
													cursor='pointer'
													padding='2'
													onClick={() => {
														// sectionDetailedTrack({
														// 	category: GA_CATEGORY_NAVBAR,
														// 	action: 'Chat View',
														// 	label: 'Rename',
														// })
														onRenameActivity(groupName)
													}}
												>
													<MdCheckCircle color='teal' />
												</Text>
											</React.Fragment>
										)}
									</Flex>
								) : (
									<Text
										fontSize='24px'
										alignSelf='flex-end'
										fontWeight='bold'
										marginLeft='4'
									>
										{chanelName}
									</Text>
								)}
							</Flex>
							{chatSelectedProfile.activity_type_category_id === 27 ||
							chatSelectedProfile.isGroupChat
								? groupView !== GROUP_PANEL_VIEW.GROUP_INFO_VIEW && (
										<Button
											size='sm'
											marginRight='7'
											as={Button}
											colorScheme={localStorage.getItem('color')}
											bg={localStorage.getItem('color')}
											rightIcon={<MdInfo fontSize='20px' />}
											onClick={() => {
												// sectionDetailedTrack({
												// 	category: GA_CATEGORY_NAVBAR,
												// 	action: 'Chat View',
												// 	label: 'Group Info View',
												// })
												// dataLayerTagManager('button_click', {
												// 	viewName: 'Chat',
												// 	buttonName: 'Group Info',
												// })
												groupInfo()
											}}
										>
											{locale['Info']}
										</Button>
								  )
								: null}
						</Flex>
						{chatSelectedProfile.activity_type_category_id !== 27 &&
						!chatSelectedProfile.isGroupChat ? (
							<Text
								fontSize='xs'
								marginLeft='50px'
								padding='0'
								color={
									onlineStatus === 'Online' ? localStorage.getItem('color') : ''
								}
							>
								{onlineStatus}
							</Text>
						) : null}
					</Heading>
					<Divider />
					{groupView === GROUP_PANEL_VIEW.GROUP_INFO_VIEW ? (
						<GroupList
							groupUsers={groupUsers}
							removeParticipant={removeParticipant}
							inviteUser={inviteUser}
							user={user}
						/>
					) : (
						<>
							<Box
								height='65vh'
								overflowY='scroll'
								m='20px'
								px='15px'
								sx={{
									'::-webkit-scrollbar': {
										width: '3px',
									},
									'::-webkit-scrollbar-thumb': {
										outline: `1px solid ${localStorage.getItem('color')}`,
										borderRadius: '50px',
										border: 'solid 3px transparent',
										backgroundClip: 'content-box',
										WebkitBackgroundClip: 'content-box',
									},
								}}
							>
								{chatData?.length > 0 &&
									chatData?.map((item, i) => (
										<>
											<SingleMessage
												key={i + 1}
												index={i}
												messages={item}
												user={user}
											/>
											<div key={i} ref={messagesEndRef} />
										</>
									))}
							</Box>
							<Divider />
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'
							>
								{attachmentURl ? (
									<Card boxShadow='md' borderRadius='md' maxW='300px'>
										<Flex spacing='4'>
											<Flex
												flex='1'
												gap='4'
												alignItems='center'
												flexWrap='wrap'
											>
												<Heading size='sm'>
													{toReturnName(attachmentURl)}
												</Heading>
											</Flex>
											<IconButton
												variant='ghost'
												colorScheme='gray'
												aria-label='See menu'
												cursor='pointer'
												size='md'
												onClick={clearFiles}
												icon={<BsX size='27' />}
											/>
										</Flex>
									</Card>
								) : (
									<Input
										placeholder={locale['Enter message here']}
										value={message}
										onKeyDown={onKeyDown}
										onChange={e => setmessage(e.target.value)}
									/>
								)}
								<Flex>
									<Tooltip hasArrow label={locale['File Upload']}>
										<IconButton
											bg='white'
											borderRadius='md'
											boxShadow='md'
											aria-label='Add participant'
											size='md'
											_focus={{
												bg: 'secondary',
											}}
											_hover={{
												bg: 'secondary',
											}}
											icon={<Icon as={ImAttachment} w={5} h={5} />}
											onClick={() => {
												inputRef.current.click()
											}}
										/>
									</Tooltip>
									<input
										type='file'
										// accept={acceptedFileTypes}
										name='file'
										onChange={onHandleFileChange}
										ref={inputRef}
										style={{ display: 'none' }}
									></input>
									<IconButton
										aria-label='Search database'
										marginLeft='2'
										icon={<MdSend />}
										onClick={() => {
											// sectionDetailedTrack({
											// 	category: GA_CATEGORY_NAVBAR,
											// 	action: 'Chat View',
											// 	label: 'Message Submit',
											// })
											// dataLayerTagManager('button_click', {
											// 	viewName: 'Chat',
											// 	buttonName: 'Send Message',
											// })
											onMessageSubmit({
												message,
												user: chatSelectedProfile,
												url: attachmentURl,
											})
											setmessage('')
											setattachmentURl('')
										}}
										background='#fff'
									/>
								</Flex>
							</Box>
						</>
					)}
				</>
			)}
		</>
	)
}

export default ChatView
