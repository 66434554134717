import React from 'react'
import { Box, Text, Avatar, Flex } from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../../../../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../../../../containers/repository/repo.utils'
const GroupUser = ({ groupUsers, removeParticipant, user }) => {
	return (
		<Box>
			{groupUsers.length &&
				(groupUsers || []).map((item, i) => (
					<Flex justifyItems='center' alignItems='center' minH='48px' key={i}>
						<Avatar size='sm' name={item.operating_asset_first_name} />
						<Text fontSize='sm' marginLeft='2'>
							{item.operating_asset_first_name}
						</Text>
						<br />
						{item.activity_creator_operating_asset_first_name ===
						item.operating_asset_first_name ? (
							<Text fontSize='xs' marginLeft='2'>
								(Creator)
							</Text>
						) : user.operating_asset_first_name ===
						  groupUsers?.[0]?.activity_creator_operating_asset_first_name ? (
							<Text
								marginLeft='2'
								cursor='pointer'
								padding='2'
								onClick={() => {
									// sectionDetailedTrack({
									//   category: GA_CATEGORY_NAVBAR,
									//   action: 'Chat View',
									//   label: 'Remove Participants',
									// })
									// dataLayerTagManager('button_click', {
									//   viewName: 'Chat',
									//   buttonName: 'Remove participant',
									// })
									removeParticipant(item)
								}}
							>
								<MdDelete fontSize='20' color='teal' />
							</Text>
						) : null}
					</Flex>
				))}
		</Box>
	)
}

export default GroupUser
