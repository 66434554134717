import React from 'react'
import {
	Box,
	Badge,
	IconButton,
	Avatar,
	Flex,
	Spacer,
	Text,
	VStack,
} from '@chakra-ui/react'
import {
	AiOutlineUserAdd,
	AiOutlineUserDelete,
	AiOutlineUsergroupAdd,
} from 'react-icons/ai'
import { getFromNow, getCurrentDateUTC } from '../../../../utils/date.utils'
import { loadAwsFile } from '../../../../utils/aws.util'
// import {
//   dataLayerTagManager,
//   sectionDetailedTrack,
// } from '../../../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../../../containers/repository/repo.utils'
const SingleChatCard = ({
	isGroupChat,
	index,
	returnUserName,
	item,
	onChatListClick,
	addPersonToGroup,
	isSelected,
	removePersonToGroup,
}) => {
	const {
		contact_phone_country_code,
		contact_phone_number,
		contact_department,
		group_icon,
	} = JSON.parse(item.activity_inline_data)

	if (group_icon)
		loadAwsFile(group_icon, d => {
			item.groupIcon = 'data:' + d.type + ';base64,' + d.data
		})

	if (item?.activity_lead_asset_image_path) {
		loadAwsFile(item?.activity_lead_asset_image_path, d => {
			item.icon = 'data:' + d.type + ';base64,' + d.data
		})
	}

	return (
		<Box
			p='4'
			key={index}
			marginBottom='3'
			cursor={!isGroupChat ? 'pointer' : ''}
			maxW='100%'
			borderWidth='1px'
			onClick={() => {
				// sectionDetailedTrack({
				//   category: GA_CATEGORY_NAVBAR,
				//   action: 'Chat View',
				//   label: 'Clicked Chat List',
				// })
				onChatListClick(item)
			}}
		>
			<Flex alignItems='center' position='relative'>
				<Box>
					<Flex alignItems='center' justifyContent='space-between'>
						{item.activity_type_category_id === 27 ? (
							<Avatar
								bg={localStorage.getItem('color')}
								size='sm'
								src={item.groupIcon}
								icon={<AiOutlineUsergroupAdd color='white' fontSize='1.5rem' />}
							/>
						) : (
							<Avatar
								size='sm'
								src={item.icon}
								name={returnUserName(item)}
								bgcolor={localStorage.getItem('color')}
								color='white'
							/>
						)}
						<VStack marginTop={isGroupChat && !isSelected ? '-4' : '0'}>
							<Text marginLeft='4'>{returnUserName(item)}</Text>
							{isGroupChat && !isSelected ? (
								<Text fontSize='xs' position='absolute' left='10' top='2'>
									+{contact_phone_country_code}
									{contact_phone_number} | {contact_department}{' '}
								</Text>
							) : (
								''
							)}
						</VStack>
					</Flex>
				</Box>
				<Spacer />
				{isGroupChat ? (
					<IconButton
						variant='outline'
						colorScheme='teal'
						aria-label='Call Segun'
						size='sm'
						icon={
							isSelected ? (
								<AiOutlineUserDelete size='25px' />
							) : (
								<AiOutlineUserAdd size='25px' />
							)
						}
						onClick={() => {
							// sectionDetailedTrack({
							//   category: GA_CATEGORY_NAVBAR,
							//   action: 'Chat View',
							//   label: isSelected
							//     ? 'Remove Person From Group'
							//     : 'Add Person To Group',
							// })
							// dataLayerTagManager('button_click', {
							//   viewName: 'Chat',
							//   buttonName: isSelected ? 'Remove Group User' : 'Add Group User',
							// })
							isSelected ? removePersonToGroup(index) : addPersonToGroup(item)
						}}
					/>
				) : (
					<Flex alignItems='center'>
						<Text>
							{getFromNow(
								item.activity_datetime_last_updated || getCurrentDateUTC()
							)}
						</Text>
						{item.asset_unread_updates_count > 0 && (
							<Badge colorScheme='red' marginLeft='2'>
								{item.asset_unread_updates_count}
							</Badge>
						)}
					</Flex>
				)}
			</Flex>
		</Box>
	)
}

export default SingleChatCard
